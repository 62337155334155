import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import roope from "../images/roope.png"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Welcome to Willy Lee - miniature schnauzers</h1>
    <div style={{ display: `flex`, justifyContent: `space-between` }}>
      <div style={{}}>
        <p>
          Irene Häyry
          <br />
          Kennel Willy Lee
          <br />
          29100 Luvia Finland
          <br />
          mobile: +358-50-63979
          <br />
          email:{" "}
          <a href="mailto:irene.hayry@willylee.fi">irene.hayry@willylee.fi</a>
          <br />
        </p>
      </div>
      <div style={{}}>
        <img src={roope} alt="Roope" />
      </div>
    </div>
  </Layout>
)

export default IndexPage
